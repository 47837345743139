<template>
  <div class="elv-accounts-derivative-table">
    <ag-grid-vue
      v-if="!!processTableConfig.length || !!tableConfigStore.processTableConfig.length"
      :style="{
        height: '100%',
        minWidth: '406px',
        maxWidth: '100%'
      }"
      class="elv-ag-theme-alpine"
      :rowHeight="42"
      :grid-options="gridOptions"
      :column-defs="tableConfigStore.processTableConfig"
      :loading-cell-renderer="TableLoadingCell"
      :loading-cell-renderer-params="loadingCellRendererParams"
      :pinned-top-row-data="pinnedTopRowData"
      :is-server-side-group-open-by-default="isServerSideGroupOpenByDefault"
      :get-row-id="getRowId"
      :get-row-height="getRowHeight"
      @cellClicked="onCellClicked"
      @grid-ready="onGridReady"
    />
  </div>
</template>
<script setup lang="ts">
import { AgGridVue } from '@ag-grid-community/vue3'
import { themeAlpine } from '@ag-grid-community/theming'
import { LicenseManager } from '@ag-grid-enterprise/core'
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping'
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model'
import { ModuleRegistry, type GridApi, DetailGridInfo, IServerSideGetRowsParams } from '@ag-grid-community/core'

import { useI18n } from 'vue-i18n'
import { emitter } from '@/lib/mitt'
import { isEmpty, uniqueId } from 'lodash-es'
import noDataImg from '@/assets/img/noData.png'
import { useEntityStore } from '@/stores/modules/entity'
import { useAccountStore } from '@/stores/modules/accounts'
import CustomTooltip from '@/components/Reports/Cell/Tooltips.vue'
import TableLoadingCell from '@/components/Reports/Cell/TableLoadingCell.vue'
import { useReportsTableConfigStore } from '@/stores/modules/reports/tableConfig'

LicenseManager.setLicenseKey(import.meta.env.VITE_TABLE_KEY)
ModuleRegistry.registerModules([RowGroupingModule, ServerSideRowModelModule])

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  dateTime: {
    type: String,
    default: ''
  },
  group: {
    type: String,
    default: ''
  },
  subGroup: {
    type: String,
    default: ''
  }
})

const { t } = useI18n()
const route = useRoute()

const entityStore = useEntityStore()
const accountStore = useAccountStore()
const tableConfigStore = useReportsTableConfigStore()
const { processTableConfig } = reactive(tableConfigStore)
const columnData = ref()
const tableDataList: any = ref([])
const firstLevelGroupDataList: any = ref([])
const secondLevelGroupDataList: any = ref([])
const gridApi = ref<GridApi | null>(null)

const overlayDrawerData: any = reactive({
  type: 'derivative',
  currentData: {}
})

const loadingCellRendererParams = reactive({
  loadingMessage: 'Loading'
})
const groupLitsData: any = reactive({
  group: {},
  listData: {}, // 分组后list的数据
  total: {}, // 总计数据
  totalCount: 0
})

// ag-grid基础配置
const gridOptions: any = reactive({
  sidebar: false,
  headerHeight: 35,
  tooltipShowDelay: 500,
  // tooltipHideDelay: 5000,
  // columnHoverHighlight: true,
  theme: themeAlpine,
  rowModelType: 'serverSide',
  groupDisplayType: 'custom', // custom || groupRows
  suppressRowTransform: true,
  suppressContextMenu: true,
  animateRows: true,
  debounceVerticalScrollbar: false,
  suppressPropertyNamesCheck: true,
  suppressServerSideFullWidthLoadingRow: true, // 骨架加载行
  rowGroupPanelShow: true,
  // serverSideInfiniteScroll: true, // 设置服务器端行模型是否使用无限滚动
  maxConcurrentDatasourceRequests: 1, // 有多少请求同时命中服务器。如果达到最大值，请求将排队
  cacheBlockSize: 50, // 缓存中每个块有多少行，即一次从服务器返回多少行
  blockLoadDebounceMillis: 300, // 加载块之前等待多少毫秒。在无限滚动和滚动许多无限块时很有用，因为它可以防止块加载直到滚动结束。
  serverSideInitialRowCount: 0, // 初始化从第几行开始显示
  defaultColDef: {
    resizable: true,
    suppressMovable: true,
    cellStyle: {
      height: '42px',
      'line-height': '42px' // 行高设置同步：跟rowHeight属性设置值一致
    },
    tooltipComponent: CustomTooltip,
    tooltipValueGetter: (params: any) => {
      return params?.value
    }
  },
  overlayLoadingTemplate: '<span class="ag-overlay-loading-center">data is loading...</span>',
  overlayNoRowsTemplate: `<div class="elv-result">
    <img src="${noDataImg}" style="width: 40px; height: 40px;margin-bottom:16px" />
    <div class="elv-result-description">
      No data matches this filter
    </div>
  </div>`
})

const entityId = computed(() => {
  return route.params?.entityId as string
})

const isDestroyed = computed(() => {
  return !gridApi.value || gridApi.value?.isDestroyed()
})

// 单元格点击事件
const onCellClicked = (cell: any) => {
  console.log(cell)
  columnData.value = cell
  overlayDrawerData.currentData.rowId = cell.node.id
}

// 分组数据处理
const groupRowDataFormatter = async (params: any) => {
  let data: any = {
    list: [],
    total: {}
  }
  const groupTwoData: any = []
  const derivativeFilter: any =
    accountStore.derivativeFilterList.find((item: any) => {
      return item.entityId === entityId.value
    }) ?? {}
  const positionParams = {
    date: props.dateTime,
    group: 'DERIVATIVE_TYPE',
    subGroup: 'ENTITY_ACCOUNT',
    ...derivativeFilter?.data
  }
  if (!groupLitsData.group?.list?.length) {
    accountStore.setRequestedList({ url: `/entity/${entityId.value}/trade/calculatePosition`, params: positionParams })
    data = await accountStore.fetchTradeCalculatePositionList(entityId.value, positionParams)
    accountStore.setRequestedList({ url: `/entity/${entityId.value}/trade/calculatePosition`, params: positionParams })
    groupLitsData.group.list = data.list
    groupLitsData.listData = data.list
    groupLitsData.total = {
      unrealizedAmountFCTotal: data.unrealizedAmountFCTotal
    }
    data = { list: data.list, totalCount: data.list.length }
    if (data?.totalCount) groupLitsData.totalCount = data.totalCount
  } else if (params.request?.endRow && params.request.startRow !== 0 && params.request.endRow !== 100) {
    if (params.request.endRow / 30) {
      data.list = groupLitsData.group?.list?.slice(params.request.startRow, params.request.endRow)
    } else {
      data.list = groupLitsData.group?.list?.slice(0, 30)
    }
  } else {
    data.list = groupLitsData.group?.list.slice(0, 30)
  }
  let rowData: any = []
  // 一级分组
  if (data?.list?.length > 0) {
    let groupIndex = 0
    data?.list.forEach((firstLevelGroupData: any, groupOneIndex: number) => {
      const tableFirstGroupData: any = []
      firstLevelGroupData.list.forEach((item: any) => {
        item.list = item?.list?.filter((groupItem: any) => groupItem.entityAccount?.status === 'NORMAL') || []
      })
      tableFirstGroupData.push({
        ...firstLevelGroupData,
        index: groupOneIndex,
        groupLevel: 1,
        label:
          firstLevelGroupData?.entityAccount?.name ?? t(`common.${firstLevelGroupData.derivativeType?.toLowerCase()}s`),
        firstLevelGroup:
          firstLevelGroupData?.entityAccount?.name ?? t(`common.${firstLevelGroupData.derivativeType?.toLowerCase()}s`)
      })
      rowData = [...rowData, ...tableFirstGroupData]
      const tableSecondGroupData: any = []
      // 二级分组
      if (firstLevelGroupData.list?.length > 0 && positionParams?.subGroup !== 'NONE') {
        firstLevelGroupData.list.forEach((secondLevelGroupData: any, groupTwoIndex: number) => {
          groupIndex += 1
          tableSecondGroupData.push({
            ...secondLevelGroupData,
            index: groupTwoIndex,
            groupThreeIndex: groupIndex - 1,
            groupLevel: 2,
            rowId: `${groupIndex - 1}${groupIndex}${Math.floor(Math.random() * 900) + 100}`,
            label: secondLevelGroupData.derivativeType
              ? t(`common.${secondLevelGroupData.derivativeType?.toLowerCase()}s`)
              : secondLevelGroupData?.entityAccount?.name,
            secondLevelGroup: secondLevelGroupData.derivativeType
              ? t(`common.${secondLevelGroupData.derivativeType?.toLowerCase()}s`)
              : secondLevelGroupData?.entityAccount?.name,
            list: secondLevelGroupData.list
          })
        })
      }
      groupTwoData.push(tableSecondGroupData)
    })
  }
  entityStore.loading = false
  firstLevelGroupDataList.value = rowData
  secondLevelGroupDataList.value = groupTwoData
  return {
    firstLevelGroupData: rowData,
    secondLevelGroupData: groupTwoData
  }
}

// 获取列表数据
const getTableGroupListData = async (params: any, level: string) => {
  const groupListParams: any = {
    page: 1,
    limit: 50
  }
  let tableGroupListData: any = []
  groupListParams.page = params.request.endRow / 50 ? Number((params.request.endRow / 50).toFixed(0)) : 1

  if (isEmpty(groupLitsData.listData)) {
    groupLitsData.listData = accountStore.tradeCalculatePositionList
  }
  let list: any = []

  switch (level) {
    case 'secondLevelGroup':
      list = secondLevelGroupDataList.value?.[params?.parentNode?.data?.index] ?? []
      break
    default:
      list = params?.parentNode?.data?.list
      break
  }
  list?.forEach((item: any, index: number) => {
    item.rowId = `${index}${Math.floor(Math.random() * 900) + 100}${
      item?.assetId ? item?.assetId : `${index}${Math.floor(Math.random() * 900) + 100}`
    }`
  })

  tableGroupListData = list?.slice(params.request.startRow, params.request.endRow)
  return {
    list: tableGroupListData,
    totalCount: list?.length
  }
}

// 获取服务器列表数据
const getServerSideDataSource = () => {
  gridApi.value?.setGridOption('serverSideDatasource', {
    getRows: async (params: IServerSideGetRowsParams) => {
      try {
        loadingCellRendererParams.loadingMessage = 'Loading'
        if (isEmpty(params.request.groupKeys)) {
          // 一级分组框架
          gridOptions.maxConcurrentDatasourceRequests = 1
          gridOptions.cacheBlockSize = 30
          gridApi.value?.hideOverlay()
          const groupRowData = await groupRowDataFormatter(params)
          const tableGroupDataTotal: number = groupLitsData.group?.list?.length ? groupLitsData.group?.list.length : 0
          if (Number(tableGroupDataTotal) === 0 && !isDestroyed.value) {
            gridApi.value?.showNoRowsOverlay()
          }
          params.success({ rowData: groupRowData.firstLevelGroupData, rowCount: tableGroupDataTotal })
        } else {
          gridOptions.cacheBlockSize = 30
          const derivativeFilter: any =
            accountStore.derivativeFilterList.find((item: any) => {
              return item.entityId === entityId.value
            }) ?? {}
          if (
            ((derivativeFilter.data?.subGroup && derivativeFilter.data?.subGroup !== 'NONE') ||
              (props.subGroup && props.subGroup !== 'NONE')) &&
            params.parentNode.level === 0
          ) {
            // 分组为二级分组
            const groupRowData = await getTableGroupListData(params, 'secondLevelGroup')
            const tableGroupDataTotal: number =
              secondLevelGroupDataList.value?.[params?.parentNode?.data?.index]?.length ?? 0
            params.success({
              rowData: groupRowData.list,
              rowCount: tableGroupDataTotal
            })
          } else {
            // 分组下的list
            const tableGroupListData = await getTableGroupListData(params, 'list')
            params.success({
              rowData: tableGroupListData?.list ?? [],
              rowCount: tableGroupListData?.totalCount ?? 0
            })
          }
        }
      } catch (error) {
        console.log(error)
        loadingCellRendererParams.loadingMessage = 'ERR'
        if (route.name === 'entity-accounts-derivative') {
          params?.fail()
        }
      }
    }
  })
}

// ag-gridAPI初始化
const onGridReady = async (params: DetailGridInfo) => {
  // 获取gridApi
  gridApi.value = params?.api as GridApi
  accountStore.setAgGridApi(params?.api as GridApi)
  // 获取服务器列表数据
  getServerSideDataSource()
}

// 刷新列表
const resetList = () => {
  groupLitsData.group = {}
  groupLitsData.listData = {}
  groupLitsData.total = {}
  tableDataList.value = []
  firstLevelGroupDataList.value = []
  secondLevelGroupDataList.value = []
  groupLitsData.totalCount = 0
  const firstDisplayedRowIndex = gridApi.value?.getFirstDisplayedRowIndex()
  if (firstDisplayedRowIndex) {
    gridApi.value?.ensureIndexVisible(firstDisplayedRowIndex || 0, 'top')
  }
  gridApi.value?.refreshServerSide({ route: [], purge: true })
}

emitter.on('resetDerivativeList', resetList)

// 当前行唯一标识
const getRowId = (params: any) => {
  // eslint-disable-next-line no-nested-ternary
  const rowId = params.data?.label
    ? `${params.data?.label}${params.data?.index}${params.data?.rowId}`
    : // eslint-disable-next-line no-nested-ternary
      params?.parentKeys
      ? `${params?.parentKeys[0]}${params.data?.rowId}`
      : params.data?.rowId
  return uniqueId(rowId)
}

const getRowHeight = (params: any) => {
  if ((params.node.level === 0 || params.node.level === 1) && params.data?.type === 'total') {
    return 80
  }
  return 42
}
// 是否展开分组
// eslint-disable-next-line no-unused-vars
const isServerSideGroupOpenByDefault = (params: any) => {
  return true
}
// 顶部行数据
const pinnedTopRowData = computed(() => {
  return [{ fieldValues: groupLitsData.total, defaultAggregation: '', name: '' }]
})

defineExpose({
  groupLitsData,
  resetList
})

onBeforeUnmount(() => {
  gridApi.value?.destroy()
  gridApi.value = null
  emitter.off('resetDerivativeList', resetList)
})
</script>

<style scoped lang="scss">
.elv-accounts-derivative-table {
  width: 100%;
  height: 100%;
  padding-bottom: 14px;
  box-sizing: border-box;
  background: url('@/assets/img/screener-watermark.png') no-repeat 58%;
  background-size: 631px 200px;
  margin-left: -1px;

  .elv-ag-theme-alpine {
    --ag-column-border: 1px solid #e4e7eb;
    --ag-header-column-separator-color: #e4e7eb;
    --ag-header-column-separator-width: 1px;
    --ag-background-color: transparent;
    --ag-foreground-color: #0e1420;
    --ag-header-foreground-color: rgb(162, 174, 186);
    --ag-header-background-color: #f9fbfe;
    --ag-odd-row-background-color: transparent;
    // --ag-header-cell-hover-background-color: rgba(255, 255, 255 , 1)
    // --ag-odd-row-background-color: rgba(248, 250, 254, 0.5);
    --ag-font-size: 14px;
    --ag-row-height: 42px;
    // --ag-line-height: 42px;
    --ag-list-item-height: 50px;
    --ag-header-column-resize-handle-height: 0%;
    --ag-header-column-resize-handle-width: 1px;
    --ag-grid-size: 5px;
    --ag-borders: 1px solid;
    --ag-border-color: #e4e7eb;
    --ag-row-border-color: #e4e7eb;
    // --ag-font-family: 'Barlow-Regular';
    --ag-borders-row: solid 1px;
    --ag-cell-horizontal-border: solid #e4e7eb;
    --ag-header-column-separator-display: block;
  }

  :deep(.ag-root-wrapper) {
    border-color: #cedee0;

    .ag-row-hover.ag-full-width-row.ag-row-group:before,
    .ag-row-hover:not(.ag-full-width-row):before {
      background-color: #f9fafb;
    }
  }

  :deep(.ag-horizontal-left-spacer, .ag-horizontal-right-spacer) {
    overflow-x: overlay;
  }

  :deep(.ag-body-horizontal-scroll-viewport) {
    overflow-x: overlay;
  }

  :deep(.ag-header-group-cell-with-group) :deep(.ag-header-cell-label) {
    color: rgb(44, 46, 48);
    font-size: x-small;
    justify-content: right;
  }

  :deep(.ag-header) {
    height: 36px !important;
    min-height: 36px !important;
    border-bottom-color: #cedee0;
  }

  :deep(.ag-sticky-top) {
    z-index: 999;
  }

  :deep(.ag-header-row):nth-child(1) {
    height: 36px !important;
    line-height: 34px;
    font-size: 11px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;

    color: $elv-color-9BA8B5;
    //   letter-spacing: 0.075em;
    background-color: #eef4fb;
  }

  :deep(.ag-pinned-left-header),
  :deep(.ag-pinned-left-cols-container) {
    box-shadow:
      6px 0px 12px rgba(0, 0, 0, 0.05),
      1px 0px 4px rgba(23, 83, 235, 0.1);
    z-index: 100;
  }

  :deep(.ag-floating-top) {
    .ag-pinned-left-floating-top,
    .ag-pinned-right-floating-top {
      .ag-row {
        border-bottom: 0px;
      }
    }

    .ag-pinned-left-floating-top {
      box-shadow:
        6px 0px 12px rgba(0, 0, 0, 0.05),
        1px 0px 4px rgba(23, 83, 235, 0.1);
    }

    .ag-pinned-right-floating-top {
      box-shadow:
        -6px 0px 12px rgba(0, 0, 0, 0.05),
        -1px 0px 4px rgba(23, 83, 235, 0.1);
    }
  }

  :deep(.ag-floating-top-viewport .ag-row) {
    border-bottom: 0px !important;
  }

  :deep(.ag-floating-bottom-viewport) {
    background: #f9fafb;
    height: 52px;

    .ag-row-pinned {
      height: 100% !important;
    }

    .ag-cell {
      height: 52px !important;
    }
  }

  .ag-floating-bottom .ag-row {
    background-color: #000;
  }

  :deep(.ag-header-cell) {
    color: $elv-color-E4E7EB;
    padding-left: 0;
    padding-right: 0;

    &::after {
      width: var(--ag-header-column-separator-width);
      background-color: var(--ag-header-column-separator-color);
    }
  }

  :deep(.ag-overlay) {
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 1));
  }

  :deep(.ag-floating-bottom-full-width-container) {
    height: 52px;
  }

  :deep(.ag-floating-bottom) {
    height: 52px !important;
    min-height: 52px !important;
    border-top-color: #cedee0;

    .ag-cell {
      font-family: 'Barlow' !important;
      font-weight: 700 !important;
      font-size: 12px !important;
    }
  }
  // 隐藏工具面板
  :deep(.ag-side-buttons) {
    display: none;
  }

  :deep(.ag-row) {
    .ag-cell {
      border-left-width: 0px;
      border-top-width: 0px;
      border-bottom-width: 0px;
      display: flex;
      align-items: center;
      padding: 0 10px;
    }
  }

  :deep(.ag-header-group-cell-label) {
    justify-content: center;
  }

  :deep(.el-button.is-text:not(.is-disabled):active) {
    background-color: $elv-color-F9FBFE;
  }

  :deep(.ag-theme-alpine .ag-tabs-header) {
    display: none;
  }

  :deep(.ag-theme-alpine .ag-tabs-body) {
    width: 150px;
    box-sizing: border-box;
  }

  :deep(.ag-tabs) {
    min-width: auto;
  }

  :deep(.ag-row-group) {
    height: 42px;
    line-height: 42px;

    .ag-cell-value {
      height: 42px !important;
      line-height: 42px !important ;
    }

    .ag-cell {
      font-family: 'Barlow' !important;
      font-weight: 700 !important;
    }
  }

  :deep(.ag-row-level-0) {
    .ag-cell-value {
      &:has(.elv-table-group-rows) {
        padding-left: 11px;
      }

      &:has(.elv-financials-total) {
        height: 80px !important;
      }

      &.ag-cell-first-right-pinned {
        &:has(.elv-financials-cell-event-main.is-pinned):hover {
          background: #edf0f3;
        }
      }
    }

    &:hover {
      background: #f9fafb;

      .elv-ag-index-select {
        display: flex;
      }
    }
  }

  :deep(.ag-row-level-0.ag-row-group) {
    background: #fbf9f7;
  }

  :deep(.ag-row-level-1.ag-row-group) {
    background: #fafcff;
  }

  :deep(.ag-row-level-2.ag-row-group) {
    background: $elv-color-F5FEF4;
  }

  :deep(.ag-row-level-1) {
    .elv-table-group-rows-content__label {
      background: $elv-color-DCE6FF;
      border: 1px solid $elv-color-C4D6FF;
    }

    .ag-cell-value {
      &:has(.elv-financials-total) {
        height: 80px !important;
      }

      &:has(.elv-table-group-rows) {
        padding-right: 0px;
      }
    }
  }

  :deep(.ag-row-level-2) {
    .elv-table-group-rows-content__label {
      background: $elv-color-D7F7D2;
      border: 1px solid $elv-color-CCEAC7;
    }

    .ag-cell-value {
      &:has(.elv-ag-group-index) {
        border-right: 0px;
      }

      &:has(.elv-table-group-rows) {
        padding-right: 0px;
      }
    }
  }

  :deep(.ag-row-level-3) {
    .elv-ag-index {
      display: none;
    }

    .ag-cell-value {
      &:has(.elv-ag-index) {
        border-right: 0px;
      }

      &:has(.elv-table-group-rows__index) {
        padding-left: 38px;
      }
    }
  }

  :deep(.ag-row-group-contracted) {
    &:first-of-type {
      .ag-cell-value {
        padding-top: 0px;
      }
    }

    .ag-cell {
      border-width: 0px;
      border-right-width: 1px;
    }

    .elv-ag-group-arrow {
      height: 42px;
    }
  }

  :deep(.ag-pinned-right-floating-bottom) {
    box-shadow:
      6px 0px 12px rgba(0, 0, 0, 0.05),
      1px 0px 4px rgba(23, 83, 235, 0.1);

    .ag-row {
      background-color: #f9fafb;
      height: 52px !important;
    }

    .ag-cell-value {
      height: 52px !important;

      &:nth-child(1) {
        border-right: 0px;
      }
    }
  }

  :deep(.ag-pinned-right-header) {
    box-shadow:
      -6px 0px 12px rgba(0, 0, 0, 0.05),
      -1px 0px 4px rgba(23, 83, 235, 0.1);
  }

  :deep(.ag-pinned-right-cols-container) {
    position: relative;

    &::before {
      position: absolute;
      width: 4px;
      height: 100%;
      content: '';
      left: -4px;
      top: 0px;
      background: linear-gradient(270deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0) 100%);
    }
  }

  :deep(.ag-row-group-expanded) {
    .ag-cell {
      border-left-width: 0px;
      border-top-width: 0px;
    }
  }

  :deep(.ag-ltr .ag-cell-focus:not(.ag-cell-range-selected):focus-within) {
    border: none;
    border-right: 1px solid $elv-color-E4E7EB !important;
  }

  :deep(.ag-header-cell-comp-wrapper) {
    height: 34px;
  }

  :deep(
      .ag-pinned-left-header
        .ag-header-row-column-group
        .ag-header-group-cell-with-group:nth-child(2)
        .ag-header-group-cell-label
    ) {
    font-family: 'Plus Jakarta Sans';
    font-weight: 500;
    font-size: 11px;
    transform: scale(0.91);
    line-height: 14px;
    color: $elv-color-9BA8B5;
    justify-content: flex-start !important;
    text-transform: lowercase;
  }
}
</style>
